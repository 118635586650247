import iconOne from '../../assets/features/1.png';
import iconTwo from '../../assets/features/2.png';
import iconThree from '../../assets/features/3.png';
import iconFour from '../../assets/features/4.png';

const featuresData = [
    {
        'icon': iconOne,
        'title': 'An toàn',
        'description': 'Chúng tôi luôn ưu tiên an toàn cho kiểm tra sức khoẻ và hơn thế nữa.'
    },
    {
        'icon': iconTwo,
        'title': 'Chấp nhận bảo hiểm',
        'description': 'Chúng tôi cung cấp khám chi trả bảo hiểm.'
    },
    {
        'icon': iconThree,
        'title': 'Tất cả dịch vụ nha khoa',
        'description': 'Chúng tôi cung cấp tất cả dịch vụ nha khoa, bao gồm chỉnh nha, implant hay veneer.'
    },
    {
        'icon': iconFour,
        'title': 'Kĩ thuật hiện đại',
        'description': 'Chúng tôi luôn cập nhật kĩ thuật cũng như thiết bị tốt nhất.'
    }
]

export default featuresData;