import React from 'react';
import './Safety.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import safetyImg from '../../assets/safety.png';

const Safety = () => {
    return (
        <section className='safty-section pt-100 section-bg section-common pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="safty-text">
                            <SectionTitle 
                                subTitle="An toàn & Chất lượng"
                                title="An toàn và chất lượng là ưu tiên hàng đầu của chúng tôi."
                                description="Toàn bộ đội ngũ chúng tôi cam kết cung cấp cho bạn những tiêu chuẩn an toàn và chất lượng cao nhất trong các dịch vụ nha khoa, được cá nhân hóa để phù hợp nhu cầu của từng thành viên trong gia đình của bạn."
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="safty-img">
                        <img src={safetyImg} alt="safty" />
                    </div>
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <SectionTitle 
                    subTitle="An toàn & Chất lượng"
                    title="An toàn và chất lượng là ưu tiên hàng đầu của chúng tôi."
                    description="Toàn bộ đội ngũ chúng tôi cam kết cung cấp cho bạn những tiêu chuẩn an toàn và chất lượng cao nhất trong các dịch vụ nha khoa, được cá nhân hóa để phù hợp nhu cầu của từng thành viên trong gia đình của bạn."
                />

                <div className="safety-video ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/f4jG8BCl5s0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div> */}
        </section>
    );
};

export default Safety;