//import iconOne from '../../assets/service/1.png';
//import iconTwo from '../../assets/service/2.png';
//import iconThree from '../../assets/service/3.png';
//import iconFour from '../../assets/service/4.png';
import imgNiengRang from '../../assets/service/niengrang_service.jpeg';
import imgImplant from '../../assets/service/implant_service.jpeg';
import imgSu from '../../assets/service/su_service.jpeg';
import imgVeneer from '../../assets/service/veneer_service.jpeg';

const ServicesData = [
    {
        'icon': imgNiengRang,
        'title': 'Niềng răng (chỉnh nha)',
        'description': 'Chúng tôi luôn áp dụng những kĩ thuật mới nhất trong niềng răng cho cả người lớn và trẻ em.',
    },
    {
        'icon': imgImplant,
        'title': 'Trồng Implant (tư vấn)',
        'description': 'Phục hồi răng đã mất từ lâu, nhiều lựa chọn, áp dụng kĩ thuật cao ít xâm lấn, nhanh hồi phục và cho chức năng tốt.',
    },
    {
        'icon': imgSu,
        'title': 'Tổng quát',
        'description': 'Nhổ răng ít xâm lấn, điều trị tuỷ, hàm khung, hàm liên kết, thẩm mỹ nụ cười, tẩy trắng răng,...',
    },
    {
        'icon': imgVeneer,
        'title': 'Răng sứ / veneer',
        'description': 'Phục hình sứ cao cấp, thẩm mỹ veneer ít xâm lấn, thiết kế nụ cười 3D theo khuôn mặt ',
    }
]

export default ServicesData;