import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
//import { Link } from 'react-router-dom';
//import { BsFillArrowRightCircleFill } from "react-icons/bs";


const Services = () => {
    return (
        <section className='service-section pt-100' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitle title="Cảm nhận tuyệt vời của sức khoẻ răng miệng" subTitle="Dịch vụ"/>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'>Nha khoa thẩm mỹ Cẩm Tú với đính hướng là một trung tâm niềng răng và phục hình chuyên sâu, chúng tôi cung cấp nhiều dịch vụ nha khoa kĩ thuật hiện đại, chất lượng cao cho bạn và cả gia đình. Bạn có thể đặt lịch hẹn tư vấn miễn phí để trải nghiệm những giây phút tuyệt vời.</p>
                    </div>
                </div>

                <div className="row">
                    {
                        ServicesData.map(singleService => <Service serviceList={singleService}/>)
                    }
                </div>
            </div>

            {/* <div className="services-link text-center">
                <Link to='/'>
                    Xem tất cả dịch vụ
                    <BsFillArrowRightCircleFill/>
                </Link>
            </div> */}
        </section>
    );
};

export default Services;