import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.scss';
import icon from '../../assets/banner/icons/Calling.png';
import Spinner from '../Spinner/Spinner';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [service, setService] = useState('niengrang');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const form = useRef();

    const resetForm = () => {
        setName('');
        setMobile('');
        setService('niengrang');
        setEmail('');
        setNote('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        //alert(`The name you entered was: ${name} ${mobile} ${service} ${email} ${note}`);
        setIsLoading(true);
        setIsSuccess(false);
        //service id: service_3e7dmc9
        //to: nhakhoacamtu.cskh@gmail.com
        emailjs.sendForm(
            'service_3e7dmc9',
            'template_uiv8tum',
            form.current,
            { publicKey: 'W5sNnKZaxcvX2UHYd'}
        )
        .then(
            () => {
                console.log('SUCCESS!');
                resetForm();
                setIsLoading(false);
                setIsSuccess(true);
            },
            (error) => {
                console.log('FAILED...', error.text);
                setIsLoading(false);
                setIsSuccess(false);
            },
        );
    }

    return (
        <form ref={form} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Họ Tên <span className='field-required'>*</span></label>
                        <input 
                            required
                            name='guest_name'
                            type="text" 
                            className="form-control" 
                            placeholder="Nhập họ tên..." 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Số điện thoại <span className='field-required'>*</span></label>
                        <input 
                            required
                            name='guest_mobile'
                            type="number" 
                            className="form-control" 
                            placeholder="Nhập số di động..." 
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Dịch vụ</label>
                        <select name='guest_service' class="form-control" value={service} onChange={e => setService(e.target.value)}>
                            <option value='niengrang'>Niềng răng (chỉnh nha)</option>
                            <option value='tongquat'>Tổng quát</option>
                            <option value='implant'>Implant</option>
                            <option value='rangsu'>Răng sứ / Veneer</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>E-mail</label>
                        <input 
                            name='guest_email'
                            type="email" 
                            class="form-control" 
                            placeholder="Nhập email..." 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    {/* <div class="form-group">
                        <label>Department</label>
                        <select class="form-control">
                            <option>Select Department</option>
                            <option>Select Department</option>
                            <option>Select Department</option>
                            <option>Select Department</option>
                        </select>
                    </div> */}
                </div>
                <div className="col-lg-12">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Nội dung</label>
                        <textarea 
                            name='guest_note'
                            class="form-control" 
                            placeholder='Nhập lời nhắn...' 
                            rows="3"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </div>
                </div>

                {isSuccess && <div className="col-lg-12">
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>Đặt hẹn thành công!</strong> Cảm ơn bạn đã quan tâm đến Nha khoa thẩm mỹ Cẩm Tú. Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>}

                <div className="col-lg-6">
                    <button type="submit" class="btn appointment-btn d-flex flex-row align-items-center">
                        <span class='px-2'>Đặt hẹn tư vấn</span>
                        {isLoading && <Spinner />}
                    </button>
                </div>
                <div className="col-lg-6">
                    <div className="appointment-call">
                        <div className='icon'>
                            <img src={icon} alt="icon" />
                        </div>
                        <div className='call-text'>
                            <p>Nha khoa Hotline / Zalo</p>
                            <h6>0888 18 99 18</h6>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;