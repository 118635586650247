import React from 'react';
import { Link } from 'react-router-dom';
import './AboutBanner.scss';
import bannerOne from '../../assets/about/banner/banner_1.png'
import bannerTwo from '../../assets/about/banner/banner_2.png'
import pattern from '../../assets/banner/pattern.png'

const AboutBanner = () => {
    return (
        <section className='section-bg section-common about-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-banner-text">
                                    <h2>Giới thiệu nha khoa</h2>
                                    <p>
                                        Nha khoa thẩm mỹ Cẩm Tú được thành lập năm 2016 bởi Thạc sĩ - Bác sĩ Lê Thị Cẩm Tú - <strong>tốt nghiệp loại giỏi, thủ khoa tốt nghiệp trường Đại Học Y Dược Hồ Chí Minh</strong>, hiện là Giảng viên Khoa Răng Hàm Mặt - với sứ mệnh tập trung vào thực hành nha khoa chuyên sâu, trong đó chỉnh nha (niềng răng) là một thế mạnh của chúng tôi, nhằm giúp bạn và gia đình có được sự chăm sóc răng miệng hoàn hảo và giữ được nụ cười thẩm mỹ, khoẻ mạnh trong cuộc sống. 
                                        <br/><br/>
                                        Bằng các kế hoạch cá nhân hoá trong các buổi tư vấn, chúng tôi sẽ cung cấp nhiều lựa chọn, phương án khác nhau cho vấn đề răng miệng của bạn, từ đó chúng tôi có thể đưa ra hướng điều trị phù hợp nhất cho bạn. Chúng tôi cam kết tiếp tục nâng cao chất lượng chuyên môn và áp dụng những kĩ thuật hiện đại nhất trong điều trị sức khoẻ răng miệng. 
                                        <br/><br/>
                                        <strong>Đội ngũ nha khoa luôn tận tâm và hết lòng trong chăm sóc bệnh nhân một cách thân thiện, chu đáo và chuyên nghiệp.</strong>
                                    </p>
                                    <div className="theme-btn">
                                        <Link to='/contact'>Liên hệ với chúng tôi</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-banner-img">
                                    <img src={bannerOne} alt="about banner"/>
                                    <img src={bannerTwo} alt="about banner two"/>
                                    <img className='pattern' src={pattern} alt="about banner two"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutBanner;