import React from 'react';
import './Faq.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

const Faq = () => {
    return (
        <section className='faq-section pt-100 pb-70'  data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitle
                    subTitle="Góc chuyên gia"
                    title="Một số câu hỏi thường gặp"
                />

                <div className="accordian-area">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Răng hô nặng có niềng được không?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Răng hô nặng (hay còn gọi là răng vẩu nặng) là tình trạng răng hàm trên nhô ra về phía trước nhiều hơn so với răng hàm dưới và cấu trúc hàm bình thường.
                                    So với hô nhẹ, tình trạng hô nặng rất dễ nhận biết bằng mắt thường. Theo đó khi ngậm miệng ở tư thế nghỉ, phần xương hàm trên sẽ nhô ra thấy rõ so với hàm dưới. 
                                    <br />Tuy nhiên để biết mức độ hô nặng hay nhẹ, bạn nên đi thăm khám với bác sĩ chỉnh nha để được chẩn đoán chính xác.
                                    <br /><br />
                                    <p>Tình trạng hô nặng gây ra rất nhiều vấn đề, bao gồm:</p>

                                    – Gây mất tự tin khi giao tiếp: Khi bị hô răng, đặc biệt là tình trạng hô nặng, bạn sẽ không thể nói cười một cách tự nhiên và thoải mái.
                                    <br />
                                    – Ăn nhai khó khăn: Tình trạng hô làm cho 2 hàm răng không thể cắn khớp chính xác, từ đó khiến việc ăn nhai trở nên khó khăn. 
                                    <br />
                                    – Tăng nguy cơ mắc bệnh lý răng miệng: Do sai lệch khớp cắn, răng hô gây ra sự không đồng đều trong việc mọc răng, gây khó khăn khi vệ sinh răng miệng. Về lâu dài có thể làm tăng nguy cơ mắc các vấn đề như sâu răng, viêm nha chu, viêm nướu,…
                                    <br />
                                    – Ảnh hưởng việc phát âm: Sự sai lệch trong khớp cắn làm cho việc phát âm không thể tròn vành, rõ chữ. Đây là nguyên nhân tạo nên rào cản trong giao tiếp và giảm tự tin của người bị hô nặng.
                                    <br /><br />
                                    <p>Răng hô nặng niềng được không và có những phương pháp nào?</p>
                                    Thạc sĩ – Bác sĩ Cẩm Tú (Bác sĩ chuyên khoa chỉnh nha tại Nha khoa Cẩm Tú) cho biết răng hô nặng nên được thăm khám và tìm cách khắc phục càng sớm càng tốt. Niềng răng là phương pháp giúp khắc phục hiệu quả, mang lại nụ cười thẩm mỹ, chuẩn khớp cắn với tình trạng hô do răng. Phẫu thuật chỉ nên được áp dụng khi hô do hàm quá nặng, không cải thiện được bằng các phương pháp bảo tồn, điển hình như niềng răng.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Nhổ răng sâu: Trường hợp cần nhổ, chi phí và lưu ý cần biết
                            </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                Sâu răng nếu không khắc phục kịp thời bằng phương pháp thích hợp thì người mắc không chỉ gặp cản trở trong ăn uống hàng ngày, mà còn có thể đối mặt với nhiều vấn đề nghiêm trọng như viêm tủy răng, mẻ răng, tổn thương thần kinh…
<br /><br />
            <p>Khi nào cần nhổ răng sâu?</p>
            – Răng sâu có nên nhổ không phụ thuộc vào mức độ sâu và sức khỏe răng miệng của từng người. Trong đó:
            <br/>– Đa phần trường hợp răng sâu nhẹ, mới chớm: Bác sĩ chỉ định làm sạch và trám răng để xử lý triệt để ổ sâu, tránh lây lan sang các răng khác. 
            <br/>– Với răng sâu vào tủy nhưng chưa ảnh hưởng đến chân răng: Bác sĩ sẽ mở buồng tủy, làm sạch phần tủy nhiễm khuẩn và trám bít lại bằng vật liệu nha khoa chuyên dụng. 
            <br/>– Đối với răng bị sâu hoàn toàn: Nếu răng bị sâu không thể phục hồi được và đi kèm với tụt lợi, viêm nha chu hoặc răng sâu chết tủy, bác sĩ thường thực hiện nhổ răng để tránh nhiễm trùng phần xương bên dưới và ngăn chặn lây lan sang các răng bên cạnh.
                                <br/><br/>
                                <p>Nhổ răng sâu có đau không?</p>
                                – Khách hàng an tâm rằng nếu nhổ răng sâu ở cơ sở nha khoa uy tín với bác sĩ chuyên khoa chuyên môn giỏi, am hiểu cấu trúc xương răng thì quá trình nhổ diễn ra nhẹ nhàng, ít đau và lành thương nhanh chóng.
<br />
– Trước khi nhổ, bác sĩ luôn bôi tê trước rồi mới tiến hành tiêm tê lên mô nướu của răng sâu. Điều này giúp giảm cảm giác đau khi tiêm tê, bệnh nhân sẽ cảm thấy dễ chịu, thoải mái trong quá trình nhổ. Sau khi nhổ xong và thuốc tê hết tác dụng, bạn có thể đau nhưng vẫn ở ngưỡng chịu được. Bạn hãy an tâm vì bác sĩ sẽ kê thêm toa thuốc giảm đau và hướng dẫn chăm sóc răng miệng kỹ càng hậu phẫu để cơn đau thuyên giảm, mô nướu nhanh chóng trở lại bình thường và ăn uống dễ dàng sau chừng 4 – 5 ngày.
<br />
– Đặc biệt, bạn lưu ý rằng, khi nhổ răng sâu hàm trên hoặc hàm dưới (nhất là các răng khôn), cần chọn lựa địa chỉ nha khoa tin cậy, có bác sĩ giàu kinh nghiệm để không gây đau và không ảnh hưởng đến dây thần kinh.
                                <br/><br/>
                                <p>Chi phí nhổ răng sâu bao nhiêu tiền?</p>
                                – Giá tiền nhổ răng sâu thường dao động từ 500.000 – 6.000.000 VND/răng tùy theo vị trí, độ khó của răng.
<br/>
– Tuy nhiên, đây chỉ là mức phí tham khảo, có thể chênh lệch và thay đổi dựa vào tình trạng răng miệng của từng người. Tốt nhất, để xác định giá nhổ răng sâu bao nhiêu, bạn nên đến phòng khám để bác sĩ thăm khám và tư vấn chi tiết chi phí.
                                <br/><br/>
                                <p>Một số lưu ý trong việc chăm sóc sau khi nhổ răng sâu</p>
                                Dưới đây là những lưu ý quan trọng sau nhổ răng bạn nên biết để nhanh cầm máu và hạn chế biến chứng:
<br/>– Ngậm chặt băng gạc mà bác sĩ đưa tối thiểu 45 – 60 phút để cầm máu.
<br/>– Uống thuốc giảm đau theo đơn thuốc bác sĩ kê.
<br/>– Dành thời gian nghỉ ngơi, thư giãn sau khi nhổ răng.
<br/>– Ưu tiên ăn thức ăn mềm, lỏng, dễ nuốt, dễ nhai, không quá nóng hoặc quá lạnh nhằm hạn chế vết thương kích ứng.
<br/>– Không khạc nhổ quá mạnh và không dùng ống hút tối thiểu 3 ngày sau phẫu thuật.
<br/>– Sau nhổ răng 1 ngày, có thể súc nước muối để làm sạch khoang miệng.
<br/>– Nếu tại vị trí nhổ răng xuất hiện các dấu hiệu bất thường như có màng trắng xuất hiện, đau dữ dội, chảy máu liên tục, sưng tấy… thì bạn cần liên hệ bác sĩ đi khám ngay.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Niềng răng hô nhẹ và một số vấn đề cần biết
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                <p>Niềng răng hô nhẹ có cần nhổ răng không?</p>
                                Thông thường, niềng răng hô cần nhổ một số răng nhất định để tạo khoảng trống cho các răng còn lại di chuyển thuận lợi. Thế nhưng, không phải trường hợp nào cũng cần nhổ răng nên bạn hãy tham vấn ý kiến bác sĩ để biết chính xác. 
                                <br/><br/>
                                <p>Có những loại niềng răng hô nhẹ nào?</p>
                                Hiện tại, có hai phương pháp niềng răng hô nhẹ thông dụng là niềng răng mắc cài và niềng răng trong suốt. Trong đó, niềng răng trong suốt được nhiều người lựa chọn hơn vì tính thẩm mỹ cao, dễ vệ sinh, ít đau và không ảnh hưởng đến sinh hoạt thường ngày.
                                <br/><br/>
                                <p>Niềng răng hô nhẹ mất bao lâu thì có hiệu quả?</p>
                                Trung bình thời gian niềng răng xong tối thiểu là 18 – 24 tháng, nhưng có thể thay đổi tùy vào khả năng đáp ứng của từng trường hợp.  
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Răng khôn mọc lệch có những kiểu nào? Cách nhận biết, xử trí
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                <p>Đặc điểm của răng khôn mọc lệch</p>
                                
                                Khi răng khôn bị mọc lệch, bạn có thể nhận biết qua một số đặc điểm sau:

                                <br/>– Đau nhức ở vùng mọc răng khôn, đôi khi có thể đau ở chân răng bên cạnh răng khôn mọc lệch. 
                                <br/>– Nướu cứng chắc, sưng lên, có thể đau khi sờ vào.
                                <br/>– Hàm cảm giác khó mở rộng, lợi bị sưng và cơ miệng khó cử động linh hoạt.
                                <br/>– Miệng xuất hiện mùi hôi khó chịu.
                                <br/>– Cơ thể người bệnh bị tăng nhiệt độ, sốt kèm đau đầu trong thời gian sắp mọc răng.
                                <br/>– Tình trạng đau nhức khiến người bệnh mệt mỏi, khó chịu khi ăn uống.
                                <br/><br/>

                                <p>Các biến chứng của răng khôn mọc lệch</p>
                                – Viêm chân răng
                                <br/>– Sâu răng, hôi miệng
                                <br/>– Chen chúc, xô đẩy các răng khác
                                <br/>– Đau nhức, khít hàm, khó ăn nhai
                                <br/><br/>
                                <p>Có nên nhổ răng khôn mọc lệch không?</p>
                                
                                Với những tác hại kể trên, có thể nói khi phát hiện răng khôn mọc lệch bạn nên  nhổ càng sớm càng tốt để tránh nguy cơ gây hại cho sức khỏe. Trừ những trường hợp như mang thai, bệnh lý tim mạch, tiểu đường, huyết áp, máu khó đông hoặc răng nằm ở vị trí tác động trực tiếp đến cấu trúc xoang hàm/dây thần kinh não bộ thì không nên nhổ răng.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Yếu tố nào quan trọng nhất khi niềng răng trong suốt?
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                <p>Niềng răng trong suốt, có nên lựa chọn giá cả làm tiêu chí hàng đầu?</p>
                                
                                Niềng răng trong suốt sau khi trở thành xu hướng HOT thì trên thị trường lại nhanh chóng xuất hiện các khay niềng giá rẻ, theo đó là những lời quảng cáo hấp dẫn như “hiệu quả nhanh”, “rút ngắn thời gian”,…

                                <br/><br/>Tuy nhiên bạn có biết, khay niềng chỉ là công cụ hỗ trợ quá trình điều trị. Quan trọng vẫn là bác sĩ giỏi và giàu kinh nghiệm mới biết ứng dụng khay, mang lại hiệu quả tối đa.  
                                <br/><br/>Nếu chỉ dựa theo giá cả khay trong suốt mà quên đi yếu tố bác sĩ có thể dẫn đến kết quả điều trị không như ý:
                                <br/>– Bác sĩ không có khả năng kết thúc ca nên kết quả không hài hòa thẩm mỹ, lệch khớp cắn gây đau khớp thái dương hàm (TMJ).
                                <br/>– Một số trường hợp bác sĩ chỉ định sai từ ban đầu (ví dụ trường hợp lệch lạc nghiêm trọng không thể niềng trong suốt mà vẫn cố niềng, hoặc bác sĩ chỉ định nhổ răng sai,…), thực hiện thao tác sai kỹ thuật có thể dẫn đến tổn thương mô mềm, lộ chân răng, thậm chí dẫn đến chết tủy răng.
                                <br/><br/>

                                <p>Niềng răng trong suốt đã có công nghệ AI hỗ trợ nên không cần quan tâm bác sĩ?</p>
                                Đây cũng là vấn đề được nhiều người thắc mắc gần đây. Hiện nay có nhiều hãng khay trong suốt lâu đời như Invisalign có phần mềm AI hỗ trợ bác sĩ lập kế hoạch điều trị. Từ đây, nhiều người lầm tưởng rằng công nghệ AI có thể thay thế bác sĩ trong việc điều trị, nhưng thực tế không phải vậy.
                                <br/><br/>Bác sĩ là người trực tiếp thăm khám, xác định mục tiêu và lộ trình điều trị. Sau đó bác sĩ mới chuyển những yêu cầu này cho kỹ thuật viên của hãng. Nhờ vào sự hỗ trợ của phần mềm AI, các kỹ thuật viên tạo ra bản mô phỏng kế hoạch điều trị sơ khởi. Trong trường hợp cần thiết, bác sĩ cũng có thể yêu cầu kỹ thuật viên chỉnh sửa lại bản kế hoạch này theo yêu cầu.
                                <br/><br/>Như vậy, không phải là công nghệ hay công ty niềng răng trong suốt chịu trách nhiệm cho kết quả niềng răng của bạn, mà chính bác sĩ chỉnh nha mới là người chịu trách nhiệm và đảm bảo cho bạn có kết quả niềng răng tốt nhất.
                                <br/><br/>
                                <p>Bác sĩ – nhân tố hàng đầu quyết định sự thành công của niềng răng trong suốt</p>
                                Hiểu được bác sĩ là nhân tố quan trọng trong việc tạo ra kết quả niềng răng toàn diện cho khách hàng (đảm bảo cả sức khỏe và thẩm mỹ), nên đội ngũ bác sĩ Nha Khoa TM Cẩm Tú luôn trau dồi kiến thức và kinh nghiệm để đạt được những tiêu chuẩn riêng về chuyên môn chỉnh nha. 
                                <br/><br/>Đặc biệt, bác sĩ tại Nha Khoa Cẩm Tú có tiêu chí kết thúc ca khắt khe, đảm bảo gương mặt hài hòa cả ở góc nhìn nghiêng và góc nhìn thẳng, đường giữa hàm trên trùng với đường giữa hàm dưới và đường giữa mặt, các răng xếp lồng múi với nhau ở đúng vị trí, hàm trên phủ hàm dưới 2 – 4 mm (độ cắn phủ sinh học), khả năng ăn nhai và phát âm tốt, cuối cùng là kết quả niềng răng phải bền vững và ổn định lâu dài.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;