import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Navbar from '../../components/Navbar/Navbar';
import './Contactus.scss';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../sections/Footer/Footer';

const Contactus = () => {
    return (
        <>
            <section className='section-bg section-common contact-section'>
                <Navbar />
                <SectionTitle 
                    title="Liên hệ với chúng tôi"
                    description="Chúng tôi luôn lắng nghe những nhu cầu của bạn trong lĩnh vực nha khoa. Hãy liên hệ ngay với Nha khoa thẩm mỹ Cẩm Tú để có những tư vấn chuyên sâu."
                />
            </section>
            <section className='contact-form-area' data-aos="fade-up" data-aos-duration="2000">
                <ContactForm />
            </section>
            <Footer />
        </>
    );
};

export default Contactus;