import React from 'react';
import './Emergency.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import EmergencyImg from '../../assets/emergency.jpg';
import { Link } from 'react-router-dom';

const Emergency = () => {
    return (
        <section className='emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-img">
                            <img src={EmergencyImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-text">
                            <SectionTitle subTitle="Nha khoa điều trị" title="Nhẹ nhàng, thân thiện trong thực hành điều trị" description="Máy móc hiện tại cùng với chuyên môn kĩ thuật cao của đội ngũ bác sĩ, điều dưỡng của chúng tôi sẽ đảm bảo cho bạn có những trải nghiệm lý thú, đáng nhớ trong cả quá trình chăm sóc răng miệng của mình."/>

                            <div className="theme-btn">
                                <Link to='/contact'>Đặt hẹn tư vấn</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Emergency;