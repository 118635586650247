import React from 'react';
import './Expert.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { BsFillCheckCircleFill } from "react-icons/bs";
import expertImg from '../../assets/expert.jpg';

const Expert = () => {
    return (
        <section className='expert-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-text">
                            <SectionTitle 
                                subTitle="Chuyên gia trong lĩnh vực nha khoa"
                                title="Nha khoa kĩ thuật cao từ đội ngũ bác sĩ, giảng viên của Đại Học Y Dược Hồ Chí Minh"
                                description=""
                            />

                            <ul>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Đội ngũ y bác sĩ giỏi chuyên môn
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Cùng nhóm trợ thủ, diều dưỡng giàu kinh nghiệm, chăm sóc nhẹ nhàng, chu đáo.
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Nhiều thiết bị, kĩ thuật nha khoa hiện đại
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Thường xuyên tham gia các khoá học nâng cao từ Hiệp hội nha khoa Việt Nam và chuyên gia Quốc Tế 
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-img">
                            <img src={expertImg} alt="expert" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Expert;