import React from 'react';
//import logo from '../../assets/footer_logo.png';
import footerLogo from '../../assets/web_footer_logo.png';
import './Footer.scss';
import { FaFacebookF, FaTiktok} from "react-icons/fa";
import { Link } from 'react-router-dom';
import call from '../../assets/footer/calling.png';
import time from '../../assets/footer/time.png';
import location from '../../assets/footer/location.png';

const Footer = () => {

    const footerMenu = [
        {
            'name' : 'Về chúng tôi',
            'link' : '/about'
        },
        // {
        //     'name' : 'Dịch vụ',
        //     'link' : '/'
        // },
        // {
        //     'name' : 'Bác sĩ',
        //     'link' : '/about'
        // },
        // {
        //     'name' : 'Blogs',
        //     'link' : '/'
        // },
        {
            'name' : 'Câu hỏi',
            'link' : '/about'
        },
        {
            'name' : 'Liên hệ',
            'link' : '/contact'
        }
    ];

    const footerContacts = [
        {
            'title': 'Điện thoại / Zalo',
            'info': '0888 18 99 18',
            'icon': call
        },
        {
            'title': 'Giờ mở cửa',
            'info': '02:00 PM - 08:00 PM',
            'icon': time
        },
        {
            'title': 'Địa chỉ',
            'info': '888A Tạ Quang Bửu, P.5, Quận 8, HCM',
            'icon': location
        }
    ]

    return (
        <footer className='pt-100 pb-70'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-5">
                        <div className="footer-logo">
                            <img src={footerLogo} alt="logo" />
                        </div>
                        <p>Nha khoa Cẩm Tú là lựa chọn hàng đầu trong nha khoa thẩm mỹ và niềng răng ở khu vực tp Hồ Chí Minh.</p>

                        <div className="social-logo">
                            <p>Theo dõi chúng tôi tại</p>
                            <ul>
                                <li><a href="https://www.facebook.com/nhakhoabstu" target='_blank' rel="noreferrer"><FaFacebookF/></a></li>
                                <li><a href="https://www.tiktok.com/@nhakhoathammycamtu" target='_blank' rel="noreferrer"><FaTiktok/></a></li>
                                {/* <li><a href="/"><FaInstagram/></a></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="footer-link">
                            <p>Xem thêm</p>
                            <ul>
                                {
                                    footerMenu.map(singleMenu => <li><Link to={singleMenu.link}>{singleMenu.name}</Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="footer-contact">
                            <p>Thông tin liên hệ</p>

                            {
                                footerContacts.map(footerContact => {
                                    return  <div className="contact-list">
                                                <div className="contact-icon">
                                                    <img src={footerContact.icon} alt="call" />
                                                </div>
                                                <div className="contact-text">
                                                    <p>{footerContact.title}</p>
                                                    <h5>{footerContact.info}</h5>
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className='copy-text'>
                        <p>&copy; Bản quyền thuộc về Nha khoa thẩm mỹ Cẩm Tú</p>
                    </div>  
                    <div className='copy-links'>
                        {/* <ul>
                            <li><Link to='/'>Terms of Use</Link></li>
                            <li><Link to='/'>Privacy Policy</Link></li>
                        </ul> */}
                    </div>                          
                </div>
            </div>
        </footer>
    );
};

export default Footer;