import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import priorityImg from '../../assets/about/priority.png';
import './Priority.scss';

const Priority = () => {
    return (
        <section className='priority-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="priority-img">
                            <img src={priorityImg} alt="Priority" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="priority-text">
                            <SectionTitle 
                                subTitle="Ưu tiên của chúng tôi" 
                                title="Bạn chính là ưu tiên của chúng tôi"
                                description="Bằng cách cá nhân hoá kế hoạch điều trị, chúng tôi sẽ cung cấp nhiều lựa chọn, phương án khác nhau cho vấn đề răng miệng của bạn, từ đó chúng tôi có thể đưa ra hướng điều trị phù hợp nhất cho bạn. Chúng tôi cam kết tiếp tục nâng cao chất lượng chuyên môn và áp dụng những kĩ thuật hiện đại nhất trong điều trị sức khoẻ răng miệng. Đội ngũ nha khoa luôn tận tâm và hết lòng trong chăm sóc bệnh nhân một cách thân thiện, chu đáo và chuyên nghiệp. "
                            />

                            <div className="theme-btn">
                                <Link to='/contact'>Đặt hẹn tư vấn</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Priority;