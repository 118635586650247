import React from 'react';
import './Appointment.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import {AiFillHome} from "react-icons/ai";
import ContactForm from '../../components/ContactForm/ContactForm';

const Appointment = () => {

    const mapLink = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.9996226966855!2d106.65947627453957!3d10.734511889411692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752e4505798d95%3A0xeb8c34937622445f!2zODg4YSDEkC4gVOG6oSBRdWFuZyBC4butdSwgUGjGsOG7nW5nIDUsIFF14bqtbiA4LCBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1721130427924!5m2!1sen!2s'

    return (
        <section className='appointment-section pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="google-map">
                            <iframe title='map' src={mapLink}></iframe>

                            <div className="location-name">
                                <AiFillHome />
                                <p>888A Tạ Quang Bửu, Phường 5, Quận 8, Hồ Chí Minh</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="appointment-form-area">
                            <SectionTitle 
                            subTitle="Đặt hẹn tư vấn"
                            title="Chăm sóc răng miệng là niềm yêu thích"
                            description="Chúng tôi luôn lắng nghe những nhu cầu của bạn trong lĩnh vực nha khoa. Hãy liên hệ với Nha khoa thẩm mỹ Cẩm Tú để có những tư vấn chuyên sâu."/>

                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Appointment;