import React from 'react';
import './Features.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import IconList from '../../components/IconList/IconList';
import featuresData from './FeaturesData';

const Features = () => {
    return (
        <section className='section-bg section-common features-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitle subTitle="Chức năng" title="Chăm sóc chu đáo từ chuyên môn cao" description="Chúng tôi cam kết cung cấp những dịch vụ tốt nhất, thuận tiện nhất cho khách hàng của mình. Do đó, chúng tôi luôn tìm tòi và áp dụng những thiết bị hiện tại, kĩ thuật chuyên môn cao với nhiều dịch vụ tiện ích khác."/>

                <div className="row align-items-center">
                    {
                        featuresData.map(singleFeature => 
                            <IconList 
                                icon={singleFeature.icon} 
                                title={singleFeature.title}
                                description={singleFeature.description}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Features;