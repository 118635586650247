import React from 'react';
import './Testimonial.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
//import imgOne from '../../assets/testimonial/1.png';
//import imgTwo from '../../assets/testimonial/2.png';
//import imgThree from '../../assets/testimonial/3.png';
import img1 from '../../assets/testimonial/bn1.png';
import img2 from '../../assets/testimonial/bn2.png';
//import img3 from '../../assets/testimonial/bn3.png';
import img4 from '../../assets/testimonial/bn4.png';
import img5 from '../../assets/testimonial/bn5.png';
import img6 from '../../assets/testimonial/bn6.png';
//import img7 from '../../assets/testimonial/bn7.png';
import img8 from '../../assets/testimonial/bn8.png';
import img9 from '../../assets/testimonial/bn9.png';
import img10 from '../../assets/testimonial/bn10.png';
import TestimoniCard from '../../components/TestimoniCard/TestimoniCard';
import { AiFillStar } from "react-icons/ai";
import Slider from "react-slick";

const Testimonial = () => {

    const testimonails = [
        {
            'img': img8,
            'name': 'Khả Nguyên',
            'description' : 'Em được bạn giới thiệu đến niềng răng tại Nha Khoa Cẩm Tú và em cẩm thấy rất hài lòng khi đạt được thành quả sau 2 năm. Em muốn gởi lời cảm ơn đến bác sĩ Tú và các anh chị trong nha khoa đã nhiệt tình hỗ trợ em trong quá trình niềng răng. Em muốn gởi lời đến các bạn trẻ rằng hãy mạnh dạn thay đổi bản thân vì một nụ cười tự tin như mình nha !',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': img6,
            'name': 'Chí Cảnh',
            'description' : 'Được đồng nghiệp giới thiệu, mình đã trải qua 1400 ngày, để đi từ Đồng Nai lên Sài Gòn, không chỉ niềng răng, mình đã làm sứ, dán veneer, tẩy trắng và chữa tuỷ tại nha khoa. Mình rất tự tin với nụ cười mới và cảm thấy lựa chọn Nha khoa TM Cẩm Tú để đồng hành là quyết định đúng đắn nhất của mình 🫰🏻🫶🏼😘',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': img1,
            'name': 'Bích Ngọc',
            'description' : 'Nha khoa tận tình như ở nhà, chị lễ tận siêu cute dễ thương, bác sĩ nhiệt tình tận tâm, các bạn trợ thủ đáng iu, siêng năng, năng động tiệt zời',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': img10,
            'name': 'Đoan Thục',
            'description' : 'Em cảm ơn nha khoa đã đồng hành cùng em 3 năm qua. Mỗi khi đi làm răng em không thấy sợ dù trước đây em rật sợ làm răng. Em cảm thấy rất an tâm khi điều trị tại nha khoa của Bs Tú.',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': img2,
            'name': 'Thu Uyên',
            'description' : 'Chuyên môn, tận tâm và trách nhiệm, đó là những gì em muốn nói về nha khoa. Cảm ơn chị Tú và đội ngũ nhân viên đã đi cùng em chặng đường vừa qua. Giữa muôn vàn nha khoa, em cảm thấy thật sự may mắn khi biết đến Nha Khoa Thẩm Mỹ Cẩm Tú 😘.',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': img4,
            'name': 'Bội Thanh',
            'description' : 'Không gian sạch sẽ, các chị bác sĩ y tá đều rất dễ thương chu đáo. 8/3 còn có quà rất dễ thương. 😍😍😍',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': img5,
            'name': 'Bội Oanh',
            'description' : 'Em rất vui vì thấy cười xinh hơn sau niềng răng. Thậm chí, em con thấy việc niềng răng khá vui, thoải mái và đây là lựa chọn sáng suốt khi em niềng răng tại Nha Khoa TM Cẩm Tú.',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': img9,
            'name': 'Huỳnh Tâm',
            'description' : 'Mình nhổ răng và làm răng sứ ở nha khoa thấy rất hài lòng. Nhổ răng về không bị đau gì, bác sĩ và nhân viên thì dễ thương nhiệt tình.',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        }
    ]

    // slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    return (
        <section className='testimonail-section section-bg section-common pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <SectionTitle 
                        subTitle="Đánh giá của khách hàng"
                        title="Những cảm nhận của khách hàng về nha khoa"
                        />
                    </div>
                    <div className="col-lg-6">
                        <p className="pt-5">Chúng tôi rất trân trọng và cảm ơn sâu sắc nhiều quý khách hàng đã ủng hộ và tiếp tục chọn <strong>Nha khoa thẩm mỹ Cẩm Tú</strong> là nơi đáng tin cậy cho vấn đề chăm sóc răng miệng của mình. Những đánh giá của quý khách hàng giúp chúng tôi ngày càng nâng cao chất lượng dịch vụ của mình để đáp ứng và phục vụ quý khách hàng tốt hơn.</p>
                    </div>
                </div>

                <Slider {...settings} className="testimoni-slider">
                    {
                        testimonails.map(testimonail => <TestimoniCard testimonail={testimonail} />)
                    }
                </Slider>
               
            </div>
        </section>
    );
};

export default Testimonial;